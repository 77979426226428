.react-trello-board {
  @apply h-[92vh] border-0 bg-snow  !important;
}

.react-trello-lane {
  @apply m-1 border-0 bg-gray3  !important;
}

.react-trello-lane .vertical {
  @apply !min-h-[250px] !w-72;
}

.react-trello-dropbox .vertical {
  @apply h-72 rounded border-2 border-gray5;
}

.react-trello-lane div:nth-child(2) {
  @apply max-h-[79vh];
}
