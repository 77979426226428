@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts.scss';
@import 'colors.scss';
@import 'search.scss';

@layer reset, base, tokens, recipes, utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ss02 {
    font-feature-settings: 'ss01', 'ss02', 'calt', 'case' on;
  }

  .tnum {
    font-feature-settings: 'ss01', 'tnum', 'calt', 'case' on;
  }

  .clean-render {
    font-feature-settings: 'ss01', 'calt', 'case' on;
  }

  input,
  *[contentEditable='true'] {
    font-feature-settings: 'ss01', 'calt', 'case' on;
  }
}

@import '@skcript/founderui-colors/green.css';
@import '@skcript/founderui-colors/greenCarbon.css';
@import '@skcript/founderui-colors/blue.css';
@import '@skcript/founderui-colors/blueCarbon.css';
@import '@skcript/founderui-colors/red.css';
@import '@skcript/founderui-colors/redCarbon.css';
@import '@skcript/founderui-colors/yellow.css';
@import '@skcript/founderui-colors/yellowCarbon.css';
@import '@skcript/founderui-colors/brown.css';
@import '@skcript/founderui-colors/brownCarbon.css';
@import '@skcript/founderui-colors/indigo.css';
@import '@skcript/founderui-colors/indigoCarbon.css';
@import '@skcript/founderui-colors/carbon.css';
@import '@skcript/founderui-colors/carbonCarbon.css';
@import '@skcript/founderui-colors/snow.css';
@import '@skcript/founderui-colors/snowCarbon.css';
@import '@skcript/founderui-colors/mauve.css';
@import '@skcript/founderui-colors/mauveCarbon.css';
@import '@skcript/founderui-colors/gray.css';
@import '@skcript/founderui-colors/grayCarbon.css';
@import '@skcript/founderui-colors/amber.css';
@import '@skcript/founderui-colors/amberCarbon.css';
@import '@skcript/founderui-colors/bronze.css';
@import '@skcript/founderui-colors/bronzeCarbon.css';
@import '@skcript/founderui-colors/crimson.css';
@import '@skcript/founderui-colors/crimsonCarbon.css';
@import '@skcript/founderui-colors/cyan.css';
@import '@skcript/founderui-colors/cyanCarbon.css';
@import '@skcript/founderui-colors/gold.css';
@import '@skcript/founderui-colors/goldCarbon.css';
@import '@skcript/founderui-colors/grass.css';
@import '@skcript/founderui-colors/grassCarbon.css';
@import '@skcript/founderui-colors/lime.css';
@import '@skcript/founderui-colors/limeCarbon.css';
@import '@skcript/founderui-colors/mint.css';
@import '@skcript/founderui-colors/mintCarbon.css';
@import '@skcript/founderui-colors/olive.css';
@import '@skcript/founderui-colors/oliveCarbon.css';
@import '@skcript/founderui-colors/orange.css';
@import '@skcript/founderui-colors/orangeCarbon.css';
@import '@skcript/founderui-colors/pink.css';
@import '@skcript/founderui-colors/pinkCarbon.css';
@import '@skcript/founderui-colors/plum.css';
@import '@skcript/founderui-colors/plumCarbon.css';
@import '@skcript/founderui-colors/purple.css';
@import '@skcript/founderui-colors/purpleCarbon.css';
@import '@skcript/founderui-colors/sage.css';
@import '@skcript/founderui-colors/sageCarbon.css';
@import '@skcript/founderui-colors/sand.css';
@import '@skcript/founderui-colors/sandCarbon.css';
@import '@skcript/founderui-colors/sky.css';
@import '@skcript/founderui-colors/skyCarbon.css';
@import '@skcript/founderui-colors/slate.css';
@import '@skcript/founderui-colors/slateCarbon.css';
@import '@skcript/founderui-colors/teal.css';
@import '@skcript/founderui-colors/tealCarbon.css';
@import '@skcript/founderui-colors/tomato.css';
@import '@skcript/founderui-colors/tomatoCarbon.css';
@import '@skcript/founderui-colors/violet.css';
@import '@skcript/founderui-colors/violetCarbon.css';
@import '@skcript/featureos-kalai/colors.css';

@import '/datepicker.css';
@import '/roadmap.css';
@import './timeline.scss';
@import 'typography.scss';
@import './cmdk.scss';

:root {
  --link: 217, 100%, 50%;
  --link-hover: 218, 100%, 35%;
  --primary: 217, 100%, 50%;
  --text-faded: 34, 3%, 54%;
  --link-dark: 244, 56%, 43%;
  --primary-dark: 244, 56%, 43%;
  --primary-light: 240, 100%, 89%;
  --yellow-light: 47, 98%, 84%;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

.bg-card {
  @apply bg-snow dark:bg-gray3;
}

/* Custom Classes */

/* Gradient Mask for Prose */

@media (max-width: 640px) {
  /* ... */
  .gradient-mask-right {
    --shadow-size: 50px;
    -webkit-mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 0px,
      black calc(100% - var(--shadow-size)),
      transparent 100%
    );
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 16px,
      black calc(100% - var(--shadow-size)),
      transparent 100%
    );
  }
}

.gradient-mask-top {
  --shadow-size: 16px;
  -webkit-mask-image: linear-gradient(
    to top,
    transparent 0%,
    black 16px,
    black calc(100% - var(--shadow-size)),
    transparent 100%
  );
  mask-image: linear-gradient(
    to top,
    transparent 0%,
    black 16px,
    black calc(100% - var(--shadow-size)),
    transparent 100%
  );
}

.gradient-mask-b {
  --shadow-size: 0px;
  -webkit-mask-image: linear-gradient(
    to top,
    transparent 0%,
    black 100px,
    black calc(100% - var(--shadow-size)),
    transparent 100%
  );
  mask-image: linear-gradient(
    to top,
    transparent 0%,
    black 100px,
    black calc(100% - var(--shadow-size)),
    transparent 100%
  );
}

/* Global Rounded */
.hn-rounded {
  @apply rounded-md;
}

/* Global Hover State Style */
.hn-hover-states {
  @apply hover:bg-coconut dark:hover:bg-gray-dark-hover;
}

/* Global Animation Duration */
.hn-animate-duration {
  @apply transition duration-200;
}

/* Blur */

/* Color Picker */

.resposive .react-colorful {
  width: auto;
}
/* Radio box */

.radio-check[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-size: 65% 100% !important;
}

.card-input-element:checked + .card-input {
  @apply border-[1px] border-primary bg-indigo3/10 transition-opacity duration-200;
}

/* Custom Classes */

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

/* input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
} */

/* AsideBar  */

.aside-open {
  @media (min-width: 768px) {
    .singlepostwidth {
      left: 270px;
    }
  }
}

.aside-close {
  @media (min-width: 768px) {
    .singlepostwidth {
      left: 0px;
    }
  }
}

.callout {
  @apply my-4 rounded-lg px-4 py-4 text-sm;
  background-color: var(--callout-blue-background);
  border: 1px solid transparent;
  color: var(--callout-blue-text);
}

.callout > * {
  color: var(--callout-blue-text);
}

.callout.green {
  background-color: var(--callout-green-background);
  color: var(--callout-green-text);
  border-color: var(--callout-green-text);
}

.callout.green > * {
  color: var(--callout-green-text);
}

.callout.yellow {
  background-color: rgb(255, 254, 236);
  color: rgb(186, 131, 4);
  border-color: rgb(186, 131, 4);
}

.callout.yellow > * {
  color: rgb(186, 131, 4);
}

.callout.red {
  background-color: var(--callout-red-background);
  color: var(--callout-red-text);
  border-color: var(--callout-red-text);
}

.callout.red > * {
  color: var(--callout-red-text);
}

/* Card Styles */

.fo-meta-card-wrapper {
  @apply rounded-md bg-gray2;
}

.fo-meta-card-header {
  @apply flex items-center justify-between gap-x-1 border-b border-gray5 px-3.5 py-2 font-medium text-gray12/80;
}

.tiptap p.is-editor-empty:first-child::before {
  @apply text-gray7;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.fos-postitem-name, .highlight-search-text {
  em {
    @apply bg-yellow5 not-italic dark:bg-yellow8;
  }
}

.prose-full-width {
  @apply w-full max-w-full;
  & > * {
    @apply w-full max-w-full;
  }
}

mark {
  @apply bg-yellow5 text-inherit dark:bg-yellow8;
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.shimmer-text {
  @apply text-gray10;
  animation: shimmer 2s ease-in-out infinite;
}

/* Custom scrollbar styling */
/* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
} */

/* Dark mode scrollbar */
/* .dark ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.dark ::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
} */
