img.ProseMirror-separator {
  @apply bg-transparent opacity-0;
}

.editors {
  @apply first:mt-0 last:mb-0;
  font-feature-settings: 'ss01', 'calt', 'case' on;
  display: block;
  flex: 1 1 0%;
  overflow-wrap: break-word;
  line-height: 1.6;
  color: hsl(var(--gray12));

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-gray12;
    margin: 1em 0 0.6em;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 0.6em;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 0.6em;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 16px;
  }

  p {
    @apply font-[400] text-gray12 first:mt-0 last:mb-0;
    margin: 0 0 10px;
    position: relative;
    overflow-wrap: break-word;
    font-size: 14px;

    img {
      @apply my-1 py-4;
    }
  }

  img, video {
    margin: 10px auto;
    border-radius: 4px;
    &[data-size='small'] {
      max-width: 50% !important;
    }
    &[data-size='medium'] {
      max-width: 75% !important;
    }
    &[data-size='full'] {
      max-width: 100% !important;
    }
    &[data-size='auto'] {
      @apply max-h-[60vh];
    }
    height: auto;
  }

  ol,
  ul {
    @apply first:mt-0;
    margin: 0 0 10px;
    padding-left: 27px;
  }

  ol > li {
    list-style: decimal;
    padding-bottom: 5px;
  }

  ul > li {
    list-style: disc;
    padding-bottom: 5px;
  }

  blockquote {
    border-left: 3px solid hsl(var(--gray6));
    margin: 15px 0;
    padding: 10px 0 10px 15px;
    font-style: italic;
    color: hsl(var(--gray11));

    p {
      &::before,
      &::after {
        content: '"';
        font-weight: bold;
      }
    }
  }

  code,
  kbd,
  samp,
  pre {
    @apply rounded bg-gray5/50 px-1 py-0.5 text-sm text-gray11;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  }

  pre {
    overflow-x: auto;
    font-weight: 400;
    font-size: 0.938rem;
    line-height: 1.6;
    margin: 1.6em 0;
    border-radius: 0.375rem;
    padding: 1.2em;

    code {
      @apply bg-transparent dark:bg-transparent;
    }
  }

  hr {
    @apply my-6 bg-gray5;
    width: 100%;
    height: 1px;
    border: none;
  }

  .image-component {
    @apply my-4 overflow-hidden rounded-sm px-[2px];
  }

  table {
    @apply my-4;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    td,
    th {
      @apply border border-gray6;
      padding: 8px;
      min-width: 1em;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      @apply bg-gray2 font-semibold;
      text-align: left;
    }

    .selectedCell::after {
      @apply bg-primary-light opacity-30;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: hsl(var(--primary));
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }

  a {
    color: hsl(var(--link));
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  strong {
    @apply font-semibold;
  }

  .has-focus > .image-component > .imgborder {
    border-radius: 2px;
    box-shadow: 0 0 0 2px hsl(var(--link));
  }

  .mention {
    @apply rounded bg-hnlightpurple/30 px-1 py-0.5 text-sm text-[#6f6fe7] no-underline dark:border-gray-dark-hover dark:bg-hnlightpurple/20 dark:text-hnlightpurple;
  }

  & p:not(.ProseMirror-trailingBreak) {
    padding: 10px 0;
  }

  & p:not(:empty) {
    padding: 0;
  }

  [data-type='emoji-mention'] {
    text-decoration: none;
    cursor: auto;
  }
  iframe {
    max-width: 100% !important;
    position: static !important;
  }
}
