@font-face {
  font-family: 'InterVariable';
  font-weight: 400 800;
  font-display: swap;
  font-style: normal;
  src: url(https://cdn.featureos.app/fonts/inter/Inter-roman.var.woff2)
    format('woff2');
}

@font-face {
  font-family: 'InterVariable';
  font-weight: 400 800;
  font-display: swap;
  font-style: italic;
  src: url(https://cdn.featureos.app/fonts/inter/Inter-italic.var.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Inter Display';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url(https://cdn.featureos.app/fonts/inter/InterDisplay-SemiBold.woff2)
    format('woff2');
}
