.cmdk-root {
  @apply border border-gray2 bg-gray1 dark:border-gray7/50;
  max-width: 640px;
  width: 100%;
  border-radius: 12px;
  padding: 8px 0;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 70px;
  border: 2px solid rgba(211, 216, 223);
  kbd {
    @apply bg-gray1;
    height: 20px;
    border-radius: 4px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-bottom-width: 2px;
    border-color: var(--gray8);
    color: var(--gray9);
    &:first-of-type {
      margin-left: 8px;
    }
  }
}

.cmdk-input {
  @apply bg-gray1 placeholder:text-gray10;
  border: none;
  width: 100%;
  font-size: 15px;
  padding: 8px 16px;
  outline: none;
  border-bottom: 1px solid var(--gray6) !important;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.cmdk-item {
  content-visibility: auto;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 5px;
  margin: 0 -8px;
  color: var(--gray12);
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;

  &:focus {
    outline: 0;
  }

  .highlight {
    font-weight: 500;
    background: var(--indigo4);
  }

  &[aria-selected='true'] {
    @apply bg-gray4;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
  }

  &:first-child {
    margin-top: 8px;
  }

  & + [cmdk-item] {
    margin-top: 4px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.cmdk-list {
  @apply border-t border-gray2 transition-opacity duration-200 dark:border-gray7;
  padding: 0 10px;
  height: 402px;
  max-height: 402px;
  overflow: auto;
  overscroll-behavior: contain;
  scroll-padding-block-end: 40px;
  transition: 100ms ease;
  transition-property: height;
}

*:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}

.cmdk-separator {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}

.cmdk-group-heading {
  user-select: none;
  font-size: 12px;
  color: var(--gray11);
  padding: 20px 10px;
  [cmdk-group-items] {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}

.cmdk-dialog {
  [cmdk] {
    width: 640px;
    transform-origin: center center;
    animation: dialogIn var(--transition-fast) forwards;
  }

  &[data-state='closed'] [cmdk] {
    animation: dialogOut var(--transition-fast) forwards;
  }
}

.cmdk-empty {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  white-space: pre-wrap;
  color: var(--gray11);
}

[cmdk-raycast-footer] {
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  position: absolute;
  background: var(--gray1);
  bottom: 0;
  padding: 8px;
  border-top: 1px solid var(--gray6);
  border-radius: 0 0 12px 12px;

  svg {
    width: 20px;
    height: 20px;
    filter: grayscale(1);
    margin-right: auto;
  }

  hr {
    height: 12px;
    width: 1px;
    border: 0;
    background: var(--gray6);
    margin: 0 4px 0px 12px;
  }

  @media (prefers-color-scheme: dark) {
    background: var(--gray2);
  }
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: '';
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: hsl(var(--primary));
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
