$item-color: white;
$item-background: #2196f3;
$item-border: 1px solid #1a6fb3;
$item-selected-color: white;
$item-selected-background: #ffc107;
$item-selected-border: 1px solid #ff9800;

$row-background-even: transparent;
$row-background-odd: rgba(0, 0, 0, 0.05);

$border-color: #bbb;
$border-width: 1px;
$thick-border-width: 2px;
$sidebar-color: #ffffff;
$sidebar-background-color: #c52020;
$list-item-padding: 0 4px;
$weekend: rgba(250, 246, 225, 0.5);

.react-calendar-timeline {
  * {
    box-sizing: border-box;
  }

  .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    height: 100% !important;
  }

  .rct-scroll {
    display: inline-block;
    white-space: normal; /* was set to nowrap in .rct-outer */
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
    /* height: calc(100vh - 133px) !important; */
  }

  .rct-item {
    &:hover {
      z-index: 3;
    }
    .rct-item-content {
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
      overflow: hidden;
      display: inline-block;
      border-radius: 2px;
      padding: 0 6px;
      height: 100%;
    }
  }

  .rct-sidebar {
    @apply w-full border-r border-gray5 first-of-type:w-[280px];
    // overflow: hidden;
    white-space: normal; /* was set to nowrap in .rct-outer */
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    min-height: calc(100vh - 104px) !important;
    max-height: 100%;
    //height: 100% !important;
    //width: 280px !important;

    /* @media (max-width: 1024px) {
      width: 100% !important;
    }

    &:first-child {
      width: 280px !important;
    }

    &.rct-sidebar-right {
      border-right: 0;
      border-left: $border-width solid $border-color;
    } */

    .rct-sidebar-row {
      @apply border-b-[0.5px] border-gray5 hover:bg-gray4/70;
      padding: $list-item-padding;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      margin: 0;

      &.rct-sidebar-row-odd {
        //background: $row-background-odd;
      }
      &.rct-sidebar-row-even {
        //background: $row-background-even;
      }
    }
  }
  .rct-sidebar-row,
  .rct-hl-even,
  .rct-hl-odd {
    @apply hover:bg-gray5;
  }

  .rct-vertical-lines {
    .rct-vl {
      @apply border-l border-gray4;
      position: absolute;
      //border-left: 1px solid $border-color;
      z-index: -1;
      &.rct-vl-first {
        //border-left-width: 2px;
      }
      &.rct-day-6,
      &.rct-day-0 {
        //background: $weekend;
        background: repeating-linear-gradient(
          -45deg,
          transparent 0 5px,
          #8787871a 6px 0.5px
        );
      }
    }
  }

  .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;

    .rct-hl-even,
    .rct-hl-odd {
      @apply z-[1] hover:bg-gray4/70;
      box-sizing: border-box;
      z-index: 3;
    }
    .rct-hl-odd {
      //background: $row-background-odd;
    }
    .rct-hl-even {
      //background: $row-background-even;
    }
  }

  .rct-cursor-line {
    @apply border border-gray4 bg-snow;
    position: absolute;
    width: 2px;
    z-index: 3;
  }

  .rct-dateHeader {
    @apply text-gray9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
  }

  .rct-dateHeader-primary {
    @apply border-x-[0.5px] border-b-[0.5px] border-gray4 font-medium text-gray10;
    background-color: initial;
  }

  .rct-header-root {
    @apply top-0 z-[4] border-b border-gray5 bg-snow  dark:bg-gray-dark;
  }

  .rct-calendar-header {
    @media (max-width: 1024px) {
      width: 0% !important;
    }
    //border: 1px solid #bbb,
    width: calc(100% - 280px) !important;
  }
}

.rtc-not-empty .rct-scroll {
  min-height: calc(100vh - 104px) !important;
}
.rtc-not-empty .rct-vl {
  min-height: calc(100vh - 104px) !important;
}
.rtc-not-empty .rct-outer {
  min-height: calc(100vh - 104px) !important;
}

.gnattgradient-r {
  -webkit-mask-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
.gnattgradient-l {
  -webkit-mask-image: linear-gradient(to left, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
.redline {
  //background-image: repeating-linear-gradient(45deg, transparent 0 4px, rgb(128 43 45 / 5%) 0px 5px) !important;
  width: 100%;
}
