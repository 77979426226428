/* New and improved color system for FeatureOS */

:root {
  --layout-base: #ffffff;
  --layout-sidebar: 0, 0%, 93%;
  --layout-border: 0, 0%, 86%;

  --type-base: #000000;
  --type-secondary: #333333;
  --type-tertiary: #888888;
  --type-link: #007bff;
  --type-link-hover: #0056b3;

  --state-default: #6c757d;
  --state-success: #28a745;
  --state-warning: #ffc107;
  --state-danger: 1, 67%, 60%;

  --element-highlight: 0, 0%, 88%;
  --element-highlight-secondary: #e9ecef;
  --element-highlight-tertiary: #dee2e6;

  --curve: 6px;
}

.dark, .carbon {
  --layout-base: #000000;
  --layout-sidebar: 0, 0%, 10%;
  --layout-border: 0, 0%, 20%;

  --type-base: #ffffff;
  --type-secondary: #e0e0e0;
  --type-tertiary: #b3b3b3;

  --state-default: #6c757d;
  --state-success: #28a745;
  --state-warning: #ffc107;
  --state-danger: 1, 67%, 60%;

  --element-highlight: 0, 0%, 20%;
  --element-highlight-secondary: #222222;
  --element-highlight-tertiary: #111111;

  --curve: 6px;
}